import Image from "next/image"
import Link from "next/link"
import { FC } from "react"
import Skeleton from "react-loading-skeleton"

import useInView from "@/lib/hooks/useInView"

import InclusiveApproachIcon from "public/icons/inclusive-approach.svg"
import SkewedRightIcon from "public/icons/skewed-right-black.svg"
import SkewedRightYellowIcon from "public/icons/skewed-right-white-yellow-border.svg"

const InclusiveApproachSection: FC = () => {
  const [isInView, divRef] = useInView()

  return (
    <section className="mb-[75px] lg:mb-[134px] max-w-[100vw] w-[90vw] lg:w-[98vw] mx-auto flex  justify-center">
      <div className="flex flex-col  items-center lg:items-start w-full md:gap-[50px]  xl:gap-[150px]  lg:flex-row lg:justify-center">
        <div className="max-w-[90vw] px-2 md:max-w-screen-sm xl:max-w-screen-md">
          <div ref={divRef} className="flex justify-center lg:justify-start">
            <InclusiveApproachIcon />
          </div>
          <h2 className="font-display text-center lg:text-left mt-[14px] ">
            <span className="text-xl md:text-2xl lg:text-3xl text-text">
              INCLUSIVE APPROACH
            </span>
            <br />
            <span className="text-lg md:text-xl lg:text-2xl text-brand">
              TO LIFESTYLE & FITNESS
            </span>
          </h2>
          <div className="flex mt-[24px] md:mt-[48px] lg:mt-0 mb-[40px] items-center justify-center lg:hidden lg:justify-end">
            <div className="relative hover:h-[500px] overflow-hidden transition-all duration-150 ease-out h-[460px] w-[460px] rounded-3xl">
              {!isInView && (
                <Skeleton className="object-cover object-center overflow-hidden transition-all duration-150 ease-out hover:scale-125" />
              )}
              {isInView && (
                <img
                  src={"/images/inclusive-approach.webp"}
                  alt="inclusive-approach"
                  height={560}
                  width={560}
                  className="object-cover object-center overflow-hidden transition-all duration-150 ease-out hover:scale-125"
                  loading="lazy"
                />
              )}
            </div>
          </div>
          <div className="space-y-8">
            <p>
              At Body Factory Bali, we believe that fitness and wellness should
              be more than just a gym membership. That&apos;s why we have
              created an all-inclusive facility that offers a{" "}
              <b>full 360-degree approach to health and wellness</b>, designed
              to support our members&apos; lifestyles and help them achieve a
              balance between work and play.
            </p>
            <p>
              <b>Our values are centered around four main areas</b>: Sleep,
              Perform, Nourish, and Restore. We understand the importance of
              each of these areas and have worked hard to create{" "}
              <b>
                an environment that supports and encourages our members to
                prioritize them in their lives.
              </b>
            </p>
            <p>
              At Body Factory Bali, we are <b>more than just a gym</b>, we are a
              community of like-minded individuals who are dedicated to leading
              a healthy, active, and balanced lifestyle. Join us today and
              discover the difference that comes with being part of the Body
              Factory Bali community.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 mt-10 md:flex-row md:justify-start">
            <Link
              aria-label="Join Us Today with a Gym Membership"
              href="/gym-memberships"
              className="relative group bg-cover bg-no-repeat text-brand w-[300px] h-[50.4px] flex items-center justify-center font-bold "
            >
              <SkewedRightIcon
                className={
                  "w-[300px] h-[50.4px] absolute group-hover:opacity-0 transition-all duration-300 ease-out"
                }
              />
              <SkewedRightYellowIcon
                className={
                  "w-[300px] h-[50.4px] absolute opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out"
                }
              />
              <span className={"z-10"}>JOIN US TODAY</span>
            </Link>
            <Link
              aria-label="Learn More about our approach"
              href="/perform"
              className="relative group text-text bg-cover bg-no-repeat hover:text-brand w-[300px] h-[55.8px] flex items-center justify-center font-bold "
            >
              <SkewedRightIcon
                className={
                  "w-[300px] rotate-180 h-[50.4px] absolute opacity-0 group-hover:opacity-100 transition-all" +
                  " duration-300 ease-out"
                }
              />
              <SkewedRightYellowIcon
                className={
                  "w-[300px] rotate-180 h-[50.4px] absolute opacity-100 group-hover:opacity-0 transition-all" +
                  " duration-300 ease-out"
                }
              />
              <span className={"z-10"}>LEARN MORE</span>
            </Link>
          </div>
        </div>
        <div className="items-center justify-center hidden lg:flex lg:justify-end">
          <div className="relative hover:h-[600px] overflow-hidden transition-all duration-150 ease-out md:h-[450px] md:w-[450px] h-[560px] w-[560px] xl:h-[560px] xl:w-[560px] rounded-3xl">
            {!isInView && (
              <Skeleton className="object-cover object-center overflow-hidden transition-all duration-150 ease-out hover:scale-125" />
            )}
            {isInView && (
              <Image
                src="/images/inclusive-approach.webp"
                alt="inclusive-approach"
                className="object-cover object-center overflow-hidden transition-all duration-150 ease-out hover:scale-125"
                fill
                loading="lazy"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default InclusiveApproachSection
